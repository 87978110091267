module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div id="replicationContent" class="innerContent replicationContent">\n\n    ';
 if (mode !== 0) { 
__p+='\n      <div class="pure-g cluster-values" style="margin-top: 0;">\n\n        <div class="pure-u-1-2 pure-u-md-1-3">\n          <div class="valueWrapper">\n            <div id="info-mode-id" class="value">'+
((__t=(info.state))==null?'':__t)+
'</div>\n            <div class="graphLabel">State</div>\n          </div>\n        </div>\n\n        <div class="pure-u-1-2 pure-u-md-1-3">\n          <div class="valueWrapper">\n            <div id="info-mode-id" class="value">'+
((__t=(info.mode))==null?'':__t)+
'</div>\n            <div class="graphLabel">Mode</div>\n          </div>\n        </div>\n\n        <div class="pure-u-1-2 pure-u-md-1-3">\n          <div class="valueWrapper">\n            ';
 if (info.role === 'follower' && mode !== 3) { 
__p+='\n              <div id="info-role-id" class="value">Slave</div>\n            ';
 } else if (info.role === 'leader' && mode !== 3) { 
__p+='\n              <div id="info-role-id" class="value">Master</div>\n            ';
 } else { 
__p+='\n              <div id="info-role-id" class="value">'+
((__t=(info.role))==null?'':__t)+
'</div>\n            ';
 } 
__p+='\n            <div class="graphLabel">Role</div>\n          </div>\n        </div>\n\n        <div class="pure-u-1-2 pure-u-md-1-3">\n          <div class="valueWrapper">\n            <div id="info-level-id" class="value">'+
((__t=(info.level))==null?'':__t)+
'</div>\n            <div class="graphLabel">Level</div>\n          </div>\n        </div>\n\n        <div class="pure-u-1-2 pure-u-md-1-3">\n          <div class="valueWrapper">\n            <div id="info-msg-id" class="value">'+
((__t=(info.health))==null?'':__t)+
'</div>\n            <div class="graphLabel">Health</div>\n          </div>\n        </div>\n\n        ';
 if (info.role === 'follower') { 
__p+='\n          <div class="pure-u-1-2 pure-u-md-1-3">\n            <div class="valueWrapper">\n              <div id="logger-lastLogTick-id" class="value"><i class="fa fa-circle-o-notch fa-spin"></i></div>\n              <div class="graphLabel">Server ID</div>\n            </div>\n          </div>\n        ';
 } else { 
__p+='\n          <div class="pure-u-1-2 pure-u-md-1-3">\n            <div class="valueWrapper">\n              <div id="logger-lastLogTick-id" class="value"><i class="fa fa-circle-o-notch fa-spin"></i></div>\n              <div class="graphLabel">Last tick</div>\n            </div>\n          </div>\n        ';
 } 
__p+='\n\n      </div>\n    ';
 } 
__p+='\n\n    ';
 if (mode === 0) { 
__p+='\n      <div class="repl-state">\n        <table class="pure-table half-split">\n            <tbody>\n              <tr>\n                <td>\n                  <div><i class="fa fa-server"></i></div>\n                  <div id="info-state-id">'+
((__t=(info.state))==null?'':__t)+
'</div>\n                </td>\n              </tr>\n                <tr>\n                  <td><div>This node is not replicating from any other node. Also there are no active slaves or followers found. </br>Please visit our <a href="https://www.arangodb.com/docs/stable/architecture-replication.html">Documentation</a> to find out how to setup replication.</div></td>\n                </tr>\n            </tbody>\n        </table>\n      </div>\n    ';
 } 
__p+='\n\n    \n    ';
 if (mode === 5) { 
__p+='\n      <div class="repl-info">\n        <div class="categoryBar">\n          <h4>Info</h4>\n        </div>\n        <table class="pure-table">\n            <tbody>\n              <tr>\n                <td>Mode:</td>\n                <td id="info-mode-id">'+
((__t=(info.mode))==null?'':__t)+
'</td>\n              </tr>\n              <tr>\n                <td>Level:</td>\n                <td id="info-level-id">'+
((__t=(info.level))==null?'':__t)+
'</td>\n              </tr>\n              <tr>\n                <td>Role:</td>\n                ';
 if (mode === 3) { 
__p+='\n                  <td id="info-role-id">'+
((__t=(info.role))==null?'':__t)+
'</td>\n                ';
 } else { 
__p+='\n                  ';
 if (info.role === 'follower') { 
__p+='\n                    <td id="info-role-id">Slave</td>\n                  ';
 } else { 
__p+='\n                    <td id="info-role-id">Master</td>\n                  ';
 } 
__p+='\n                ';
 } 
__p+='\n              </tr>\n            </tbody>\n        </table>\n      </div>\n    ';
 } 
__p+='\n\n    ';
 if (info.role === 'leader') { 
__p+='\n      <div class="repl-comparison">\n        <div class="categoryBar">\n          <h4>Comparison</h4>\n        </div>\n        \n        <div class="pure-g cluster-graphs">\n\n          <div class="pure-u-1-1 pure-u-md-1-2">\n            <div class="graphWrapper">\n              <div id="replicationTimeChart">\n                <svg></svg>\n              </div>\n              <div class="graphLabel">COMMUNICATION</div>\n            </div>\n          </div>\n\n          <div class="pure-u-1-1 pure-u-md-1-2">\n            <div class="graphWrapper">\n              <div id="replicationTickChart">\n                <svg></svg>\n              </div>\n              <div class="graphLabel">LAST TICKS</div>\n            </div>\n          </div>\n\n        </div>\n\n      </div>\n\n      ';
 if (mode === 3) { 
__p+='\n        <div class="repl-nodes">\n          <div class="categoryBar">\n            <h4>Nodes</h4>\n          </div>\n        </div>\n        <table class="pure-table">\n            <tbody>\n              <tr>\n                <td>Leader:</td>\n                <td id="nodes-leader-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\n              </tr>\n              <tr>\n                <td>Followers:</td>\n                <td id="nodes-followers-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\n              </tr>\n            </tbody>\n        </table>\n      ';
 } 
__p+='\n    ';
 } 
__p+='\n\n    ';
 if (info.role === 'leader') { 
__p+='\n      <div class="repl-logger">\n        <div class="categoryBar">\n          ';
 if (mode === 3) { 
__p+='\n            <h4>Leader State</h4>\n          ';
 } else { 
__p+='\n            <h4>Master State</h4>\n          ';
 } 
__p+='\n        </div>\n        <table class="pure-table">\n            <tbody>\n              <tr>\n                <td>Server ID:</td>\n                <td id="logger-serverid-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\n              </tr>\n              <tr>\n                <td>Time:</td>\n                <td id="logger-time-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\n              </tr>\n              <tr>\n                <td>Operations:</td>\n                <td id="logger-totalEvents-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\n              </tr>\n            </tbody>\n        </table>\n      </div>\n\n      <div class="repl-logger-clients">\n        <div class="categoryBar">\n          ';
 if (mode === 3) { 
__p+='\n            <h4>Follower States</h4>\n          ';
 } else { 
__p+='\n            <h4>Slave States</h4>\n          ';
 } 
__p+='\n        </div>\n        <table class="pure-table" id="repl-logger-clients">\n            <thead>\n              <tr>\n                <th>Syncer ID</th>\n                <th>Server ID</th>\n                <th>Client info</th>\n                <th>Time</th>\n                <th>Last served tick</th>\n              </tr>\n            </thead>\n            <tbody>\n            </tbody>\n        </table>\n      </div>\n    ';
 } 
__p+='\n\n    ';
 if (info.role === 'follower') { 
__p+='\n      <div class="repl-applier">\n        <div class="categoryBar">\n          ';
 if (mode === 3) { 
__p+='\n            <h4>Follower States</h4>\n          ';
 } else { 
__p+='\n            <h4>Slave States</h4>\n          ';
 } 
__p+='\n        </div>\n\n        <table id="repl-follower-table" class="pure-table" style="width: 100%">\n          <thead>\n            <tr>\n              <th>Database</th>\n              <th>Enabled</th>\n              <th>Phase</th>\n              <th>Replicating from</th>\n              <th>Last tick</th>\n              <th>State</th>\n            </tr>\n          </thead>\n\n          <tbody>\n          </tbody>\n\n        </table>\n      </div>\n    ';
 } 
__p+='\n\n  </div>\n';
}
return __p;
};
