module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <div id="shardsContent" class="innerContent">\n      ';
 var genClass1 = 'pure-u-6-24'; 
__p+='\n      ';
 var genClass2 = 'pure-u-6-24'; 
__p+='\n      ';
 var disabled = ' '; 
__p+='\n      ';
 var collectionName; 
__p+='\n      ';
 var first = 0; 
__p+='\n\n      ';

      var collectionInSync = function(name) {
        var result = false;
        if (_.isEqual(collections[name].Current, collections[name].Plan)) {
          return true;
        }
        return result;
      };
      
__p+='\n\n      ';
 _.each(collections, function(collection, name) { 
__p+='\n        <div class="sectionShard" id="'+
((__t=(name))==null?'':__t)+
'">\n        ';
 collectionName = name
__p+='\n\n          ';
 if (first === 0) { 
__p+='\n            <div class="sectionHeader pure-g" style="margin-top: -20px;">\n            ';
 first++; 
__p+='\n          ';
 } else { 
__p+='\n            <div class="sectionHeader pure-g">\n          ';
 } 
__p+='\n\n          <div class="pure-u-22-24">\n            <div class="title" style="position: relative; top: -4px;">\n              '+
((__t=( name ))==null?'':__t)+
'\n            </div>\n          </div>\n          <div class="pure-u-2-24 shardSyncIcons">\n              ';
 if (visible === name) { 
__p+='\n                <i style="margin-left: 10px; color: #fff;" class="fa fa-arrow-down"></i>\n              ';
 } else { 
__p+='\n                <i style="margin-left: 10px; color: #fff;" class="fa fa-arrow-right"></i>\n              ';
 } 
__p+='\n            ';
 if (collectionInSync(name)) { 
__p+='\n              <i style="margin-left: 10px;" class="fa fa-check-circle"></i>\n            ';
 } else { 
__p+='\n              <i style="margin-left: 10px;" class="fa fa-times-circle"></i>\n            ';
 } 
__p+='\n          </div>\n        </div>\n\n        ';
 if (visible === name) { 
__p+='\n          <div class="sectionShardContent">\n        ';
 } else { 
__p+='\n          <div class="sectionShardContent" style="display: none">\n        ';
 } 
__p+='\n        <div class="pure-g pure-table pure-table-header pure-title" style="clear: both">\n          <div class="pure-table-row">\n            <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">Shard</div>\n            <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">Leader</div>\n            <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left">Followers</div>\n            <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' right">Sync</div>\n          </div>\n        </div>\n\n        ';
 var counter = 0; var shardClass; 
__p+='\n        ';
 _.each(collection.Plan, function(shard, name) { 
__p+='\n          <div class="pure-g pure-table pure-table-body">\n\n            ';
 if (shard.progress) { 
__p+='\n            ';
   shardClass = 'disabled';
__p+='\n            ';
 } 
__p+='\n\n            <div id="'+
((__t=(collectionName))==null?'':__t)+
'-'+
((__t=(name))==null?'':__t)+
'" class="pure-table-row noHover '+
((__t=( shardClass ))==null?'':__t)+
'" collection="'+
((__t=( collectionName ))==null?'':__t)+
'" shard="'+
((__t=( name ))==null?'':__t)+
'" leader="'+
((__t=( shard.leader))==null?'':__t)+
'">\n\n              ';
 if (shard.leader.substring(0,1) === '_') { 
__p+='\n              ';
   shard.leader = shard.leader.slice(1) 
__p+='\n              ';
 } 
__p+='\n\n              <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">'+
((__t=( name ))==null?'':__t)+
'</div>\n              <div class="shardLeader '+
((__t=( genClass1 ))==null?'':__t)+
' positive left"><span>'+
((__t=( shard.leader ))==null?'':__t)+
'</span></div>\n\n              ';
 var found = null;  
__p+='\n              ';
 _.each(shard.followers, function(db) {  
__p+='\n                ';
 if (db === shard.leader) { 
__p+='\n                  ';
 found = true; 
__p+='\n                ';
 } 
__p+='\n              ';
 }); 
__p+='\n\n              ';
 if (found) { 
__p+='\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid"><i class="fa fa-circle-o-notch fa-spin"></i></div>\n              ';
 } else { 
__p+='\n                ';
 if (shard.followers.length === 0) { 
__p+='\n                  <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left"> no followers </div>\n                ';
 } else { 
__p+='\n                  ';
 var string = ''; 
__p+='\n                  ';
 var counter2 = 0; 
__p+='\n                  ';
 var shardCssClass = 'inSync'; 
__p+='\n\n                  ';
 var shardsNotInSync = 0; 
__p+='\n                  ';
 _.each(shard.followers, function(db) {  
__p+='\n\n                    ';
 if (collections[collectionName].Current[name].followers.indexOf(db) > -1) { 
__p+='\n                      ';
 shardCssClass = 'inSync'; 
__p+='\n                    ';
 } else { 
__p+='\n                      ';
 shardCssClass = 'notInSync'; 
__p+='\n                      ';
 shardsNotInSync++; 
__p+='\n                    ';
 } 
__p+='\n\n                    ';
 if (shard.followers.length === 1) { 
__p+='\n                      ';
 string += '<span class="' + shardCssClass + '">' + db + '</span> '; 
__p+='\n                    ';
 } else { 
__p+='\n                      ';
 if (counter2 === 0) { 
__p+='\n                        ';
 string += '<span class="' + shardCssClass + '">' + db + '</span>'; counter2++; 
__p+='\n                      ';
 } else { 
__p+='\n                        ';
 string += ", " + '<span class="' + shardCssClass + '">' + db + '</span>'; 
__p+='\n                      ';
 } 
__p+='\n                    ';
 } 
__p+='\n                  ';
 });  
__p+='\n                  \n                  <div class="shardFollowers '+
((__t=( genClass2 ))==null?'':__t)+
' left">'+
((__t=( string ))==null?'':__t)+
'</div>\n                ';
 } 
__p+='\n\n                ';
 if (collectionInSync(collectionName)) { 
__p+='\n                  <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><i class="fa fa-check-circle"></i></div>\n                ';
 } else { 
__p+='\n                  ';
 if (shardsNotInSync > 0) { 
__p+='\n                    <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><span class="shardProgress"><i class="fa fa-circle-o-notch fa-spin fa-fw"></i></span></div>\n                  ';
 } else { 
__p+='\n                    <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><i class="fa fa-check-circle"></i></div>\n                  ';
 } 
__p+='\n                ';
 } 
__p+='\n\n              ';
 } 
__p+='\n\n            </div>\n          </div>\n\n          ';
 counter++; 
__p+='\n        ';
 }); 
__p+='\n        </div>\n\n      </div>\n      ';
 }); 
__p+='\n    <div style="width: 100%; height: 70px;">\n      ';
 if (frontendConfig.db === '_system') { 
__p+='\n        <button id="rebalanceShards" style="margin-top: 20px; margin-bottom: 20px;  margin-right: 10px;" class="button-success pull-right">Rebalance Shards</button>\n      ';
 } 
__p+='\n    </div>\n  </div>\n\n';
}
return __p;
};
