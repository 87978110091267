module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <div id="nodesContent" class="innerContent">\n\n  ';
 if (Object.keys(coords).length > 0) { 
__p+='\n\n    ';
 var disabled = ''; 
__p+='\n\n    <div class="pure-g">\n\n    <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-2">\n\n    <div class="sectionHeader pure-g">\n      <div class="pure-u-1-5">\n        <div class="title">\n          Coordinators\n        </div>\n      </div>\n\n\n      <div class="pure-u-4-5">\n\n        ';
 if (scaling === true && frontendConfig.db === '_system') { 
__p+='\n          <div class="scaleGroup" id="scaleCoords">\n            <div style="text-align: -webkit-right;">\n            <span class="left" id="removeCoord"><i class="fa fa-minus"></i></span>\n            <input id="plannedCoords" type="text" value="'+
((__t=( plannedCoords ))==null?'':__t)+
'"/>\n            <span class="right" id="addCoord"><i class="fa fa-plus"></i></span>\n            </div>\n          </div>\n        ';
 } 
__p+='\n\n        <div class="information" id="infoCoords">\n          <span class="positive"><span> '+
((__t=( scaleProperties.coordsOk ))==null?'':__t)+
' </span><i class="fa fa-check-circle"></i></span>\n          ';
 if (scaleProperties.coordsError) { 
__p+='\n            <span class="negative"><span> '+
((__t=( scaleProperties.coordsError ))==null?'':__t)+
' </span><i class="fa fa-exclamation-circle"></i></span>\n          ';
 } 
__p+='\n          ';
 if (scaleProperties.coordsPending && scaling === true && frontendConfig.db === '_system') { 
__p+='\n            <span class="warning"><span> '+
((__t=( scaleProperties.coordsPending ))==null?'':__t)+
' </span><i class="fa fa-circle-o-notch fa-spin"></i></span>\n            <button class="abortClusterPlan button-navbar button-default">Abort</button>\n          ';
 } 
__p+='\n        </div>\n      </div>\n\n    </div>\n\n    <div class="pure-g cluster-nodes-title pure-table pure-table-header pure-title" style="clear: both">\n      <div class="pure-table-row">\n        <div class="pure-u-9-24 left">Name</div>\n        <div class="pure-u-9-24 left">Endpoint</div>\n        <div class="pure-u-3-24">Version</div>\n        <div class="pure-u-2-24 mid hide-small">Since</div>\n        <div class="pure-u-1-24 mid"></div>\n      </div>\n    </div>\n\n    <div class="pure-g cluster-nodes coords-nodes pure-table pure-table-body">\n      ';
 _.each(coords, function(node, key) { 
__p+='\n        ';
 var id = node.id + "-node"; 
__p+='\n\n        <div class="pure-table-row '+
((__t=( disabled ))==null?'':__t)+
'" node="'+
((__t=( id ))==null?'':__t)+
'">\n\n          <div class="pure-u-9-24 left" title="'+
((__t=( node.id ))==null?'':__t)+
'">\n            '+
((__t=( node.ShortName ))==null?'':__t)+
'\n            <i class="fa fa-bar-chart"></i>\n            ';
 if (node.CanBeDeleted && frontendConfig.db === '_system') { 
__p+='\n              <i class="fa fa-trash-o"></i>\n            ';
 } 
__p+='\n          </div>\n          <div class="pure-u-9-24 left">'+
((__t=( node.Endpoint ))==null?'':__t)+
'</div>\n\n          <div class="pure-u-3-24">'+
((__t=( node.Version ))==null?'':__t)+
'</div>\n\n          ';
 var formatted = node.LastAckedTime.substr(11, 18); 
__p+='\n          ';
 var full = node.LastAckedTime; 
__p+='\n          <div class="pure-u-2-24 hide-small mid" title="'+
((__t=( full ))==null?'':__t)+
'">'+
((__t=( formatted ))==null?'':__t)+
'</div>\n\n          ';
 if (node.Status === 'GOOD') { 
__p+='\n            <div class="pure-u-1-24 mid state"><i class="fa fa-check-circle tippy" title="'+
((__t=( node.SyncStatus ))==null?'':__t)+
'"></i></div>\n          ';
 } else { 
__p+='\n            <div class="pure-u-1-24 mid state"><i class="fa fa-exclamation-circle"></i></div>\n          ';
 } 
__p+='\n\n        </div>\n\n      ';
 }); 
__p+='\n    </div>\n  </div>\n\n  ';
 } 
__p+='\n\n  ';
 if (Object.keys(dbs).length > 0) { 
__p+='\n    ';
 var disabled = ''; 
__p+='\n    ';
 disabled = " dbserver"; 
__p+='\n    <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-2">\n      <div class="sectionHeader pure-g">\n        <div class="pure-u-1-5">\n          <div class="title">\n            DB Servers\n          </div>\n        </div>\n\n        <div class="pure-u-4-5">\n\n          ';
 if (scaling === true && frontendConfig.db === '_system') { 
__p+='\n            <div class="scaleGroup" id="scaleCoords">\n              <div style="text-align: -webkit-right;">\n              <span class="left" id="removeDBs"><i class="fa fa-minus"></i></span>\n              <input id="plannedDBs" value="'+
((__t=( plannedDBs ))==null?'':__t)+
'" type="text"/>\n              <span class="right" id="addDBs"><i class="fa fa-plus"></i></span>\n              </div>\n            </div>\n          ';
 } 
__p+='\n\n          <div class="information" id="infoDBs">\n            <span class="positive"><span> '+
((__t=( scaleProperties.dbsOk ))==null?'':__t)+
' </span><i class="fa fa-check-circle"></i></span>\n            ';
 if (scaleProperties.dbsError) { 
__p+='\n              <span class="negative"><span> '+
((__t=( scaleProperties.dbsError ))==null?'':__t)+
' </span><i class="fa fa-exclamation-circle"></i></span>\n            ';
 } 
__p+='\n            ';
 if (scaleProperties.dbsPending && scaling === true && frontendConfig.db === '_system') { 
__p+='\n              <span class="warning"><span> '+
((__t=( scaleProperties.dbsPending ))==null?'':__t)+
' </span><i class="fa fa-circle-o-notch fa-spin"></i></span>\n              <button class="abortClusterPlan button-navbar button-default">Abort</button>\n            ';
 } 
__p+='\n          </div>\n\n        </div>\n\n      </div>\n\n    <div class="pure-g cluster-nodes-title pure-table pure-table-header pure-title" style="clear: both">\n      <div class="pure-table-row">\n        <div class="pure-u-9-24 left">Name</div>\n        <div class="pure-u-9-24 left">Endpoint</div>\n        <div class="pure-u-3-24">Version</div>\n        <div class="pure-u-2-24 mid hide-small">Since</div>\n        <div class="pure-u-1-24 mid"></div>\n      </div>\n    </div>\n\n  ';
 } 
__p+='\n\n    <div class="pure-g cluster-nodes dbs-nodes pure-table pure-table-body">\n      ';
 _.each(dbs, function(node, key) { 
__p+='\n        ';
 var id = node.id + "-node"; 
__p+='\n\n        <div class="pure-table-row '+
((__t=( disabled ))==null?'':__t)+
'" node="'+
((__t=( id ))==null?'':__t)+
'">\n\n          <div class="pure-u-9-24 left" title="'+
((__t=( node.id ))==null?'':__t)+
'">\n            '+
((__t=( node.ShortName ))==null?'':__t)+
'\n            <i class="fa fa-bar-chart"></i>\n            ';
 if (node.CanBeDeleted && frontendConfig.db === '_system') { 
__p+='\n              <i class="fa fa-trash-o"></i>\n            ';
 } 
__p+='\n          </div>\n          <div class="pure-u-9-24 left">'+
((__t=( node.Endpoint ))==null?'':__t)+
'</div>\n\n          <div class="pure-u-3-24">'+
((__t=( node.Version ))==null?'':__t)+
'</div>\n\n          ';
 var formatted = node.LastAckedTime.substr(11, 18); 
__p+='\n          ';
 var full = node.LastAckedTime; 
__p+='\n          <div class="pure-u-2-24 hide-small mid" title="'+
((__t=( full ))==null?'':__t)+
'">'+
((__t=( formatted ))==null?'':__t)+
'</div>\n          \n          ';
 if (node.Status === 'GOOD') { 
__p+='\n            <div class="pure-u-1-24 mid state"><i class="fa fa-check-circle tippy" title="'+
((__t=( node.SyncStatus ))==null?'':__t)+
'"></i></div>\n          ';
 } else { 
__p+='\n            <div class="pure-u-1-24 mid state"><i class="fa fa-exclamation-circle"></i></div>\n          ';
 } 
__p+='\n\n        </div>\n      ';
 }); 
__p+='\n    </div>\n  </div>\n\n  </div>\n  </div>\n\n';
}
return __p;
};
